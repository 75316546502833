import type { App } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { queryClient } from "@/queryClient";

export default (app: App) => {
	app.use(VueQueryPlugin, { queryClient });
	app.config.warnHandler = (message) => {
		if (message.includes("Extraneous non-props attributes")) {
			return;
		}
		console.warn(message);
	};
};
